import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { Size } from '@malirang/front-share';

@Component({
  selector: 'app-center-icon',
  styleUrls: ['./center-icon.component.scss'],
  template: `
    <div
      [style]="{ 'background-color': currentColor() }"
      [attr.data-size]="size()"
      class="center-icon"
    >
      <img
        src="/my-page/profile-icon.svg"
        [attr.data-size]="size()"
        class="icon"
      />
    </div>
  `,
  imports: [CommonModule],
})
export class CenterIconComponent {
  color = input<string>('#1693C9');
  size = input<Size>('md');

  disabled = input<boolean>(false);

  currentColor = computed(() => {
    if (this.disabled()) return '#D1D5DB';
    const color = this.color() || '#1693C9';
    return color;
  });
}
